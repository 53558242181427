export default {
    title: 'Missing Slots for Inbound',
    location: '/business/missing-slots-for-inbound',
    tabs: [
        {
            name: 'By Country',
            link: `/business/missing-slots-for-inbound`
        },
        {
            name: 'Trend',
            link: `/business/missing-slots-for-inbound/:market`
        },
        // {
        //     name: 'Top 10',
        //     link: '/customer/customer-order-quality/'
        // }
    ]
}
